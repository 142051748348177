import Vue from "vue";
import Vuex from 'vuex';
Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
        appKey:"p5tvi9dspvx04",
        showLoginModal:false,
        userEquipment:'',
        userInfo: {
            authorization: '',
            imToken: '',
            portrait: '',
            type: '',
            userId: '',
            userName: '',
            sig:''
        },
        seatInfoList:[],
        config:{
            // 用户配置
        },
        userData:{
            //用户的界面信息
        },
        privateImList:[
            //私聊信息
        ],
        sessionImList:[]  //会话列表
    },
    getters: {//如果要使用watch观察状态改变那么一定配置这一项
        // im: () => this.state.im,
        // imgList: () => this.state.imgList
        // privateImList: () => this.state.privateImList
    },
    mutations: {//需要执行多个state 的操作 , 使用 mutations 会来触发会比较好维护
        setUserEquipment(state, val){//用户当前的访问设备，移动端 or pc
            state.userEquipment = val;
        },
        updateUserInfo(state, newVal) {  //更新用户信息
            state.userInfo = { ...state.userInfo, ...newVal }
        },
        setLoginModal(state,val){
            state.showLoginModal = val;
        },
        updateConfig(state, newVal) {  //更新配置
            state.config = { ...state.config, ...newVal }
        },
        updateSeatInfoList(state, newVal) {  //更新麦序列表
            state.seatInfoList = { ...state.seatInfoList, ...newVal }
        },
        updateUserData(state, newVal) {  //更新麦序列表
            state.userData = { ...state.userData, ...newVal }
        },
    },
    actions: {//需要执行多个 mutations 就需要用 action 了
        updatePrivateImList(state, newVal) {  //更新私聊信息
            state.privateImList = { ...state.privateImList, ...newVal }
        },
        updateSessionImList(state, newVal) {  //更新会话列表
            state.sessionImList = { ...state.sessionImList, ...newVal }
        },
    }
})

export default store
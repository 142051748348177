import Axios from "../utils/axios";
import httpConfig from "./env"
// VUE_APP_SERVER_URL1 = http://passport.gamebook.vip/
// VUE_APP_SERVER_URL2 = http://my.gamebook.vip/
// VUE_APP_SERVER_URL3 = http://api-play.gamebook.vip/
let api1 = httpConfig.api1;
let api2 = httpConfig.api2;
let api3 = httpConfig.api3;
export default {
    getGodInfo: (obj) => { //获取用户详情
        return Axios.post(api3 + "/home/share_show_info", {...obj}, {})
    },
    getSkillDetails: (obj) => { //获取技能卡详情
        return Axios.post(api3 + "/home/share_skill_detail", {...obj}, {})
    },
    getSkillComments: (obj) => { //获取技能卡评价
        return Axios.post(api3 + "/home/share_skill_comments", {...obj}, {})
    },
}
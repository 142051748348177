import 'babel-polyfill';
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import 'normalize.css'  //css reset工具
import './icons'
Vue.config.productionTip = false
Vue.prototype.$store = store;
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

<template>
<div class="pc-header">
  <a href="#"><img class="pc-logo" src="../../assets/imgs/pc/logo.png" alt=""></a>
</div>
</template>

<script>
export default {
  name: "headMenu",
  props:{
    state:Number,//高亮被选中的菜单
  },
  data(){
    return{
      navigationList:["带带APP","游戏陪玩","语音聊天室","伴奏上传","关于我们"],
      stateNumber: this.state
    }
  },
  methods:{
    navigationClick:function (index) {
      this.stateNumber = index;
    },
  },
}
</script>

<style scoped>
.pc-header{
  position: absolute;
  z-index: 100;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 30px 20px 30px 0px;
  width: 100%;
  height: 90px;
  background-color:rgb(51,51,51,50%);
}
.pc-header>a{
  border: 0;
  outline: none;
  padding-right: 45.1%;
}
.pc-logo{
  height: 50px;
}
</style>
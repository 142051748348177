<template>
<div class="mobile-header">
  <div class="header-bg"></div>
  <div class="mobile-logo" >
  </div>
</div>
</template>

<script>

export default {
  name: "mobileHeader"

}
</script>

<style scoped>
.mobile-header{
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  width: 100%;
  height: 6.65%;
}
.header-bg{
  background: #000000 center no-repeat ;
  opacity: 0.5;
  width: 100%;
  height: 100%;
  position: absolute;
}
.mobile-logo{
  height: 100%;
  opacity: 1;
  background: url("../../assets/imgs/mobile/headerLogo.png") 30px center no-repeat ;
  background-size: auto 50%;
  position: relative;
}
</style>
<template>
  <div class="pc-container">
    <!--    <div class="pcView-nextPage"></div>-->
    <div class="swiper-container pcView-swiper">
      <Swiper :options="swiperOptions" ref="pageSwiper">
        <!--        趣开黑-->
        <div class="swiper-slide">
          <Header v-bind:state='stateNumber'/>
          <img class="swiperBgImg" src="../../assets/imgs/pc/background.png" alt="">
          <div class="swiper1-1 theSwiper ani" swiper-animate-effect="zoomIn"
               :swiper-animate-duration="animateDuration">
            <img class="swiper1-title" src="../../assets/imgs/pc/title.png" alt="">
          </div>
                            <div class="swiper1-btn">
                              <div class="swiper1-2 theSwiper">
                                <div class="iosBox">
                                  <div class="iosCodeBg" v-bind:style="' visibility: '+iosVisibility+';'">
<!--                                    <p class="iosQrCode"></p>-->
                                    <img class="iosQrCode" src="../../assets/imgs/pc/iosCode.png" alt="">
                                  </div>
                                  <img @mouseover="hoverIos(true)" @mouseleave="hoverIos(false)" class="swiper1-ios ani"
                                       swiper-animate-effect="fadeInUp" :swiper-animate-duration="animateDuration"
                                       src="../../assets/imgs/pc/ios.png" alt="">
                                </div>
                                <div class="androidBox">
                                  <div class="androidCodeBg" v-bind:style="' visibility: '+androidVisibility+';'">

                                    <img class="androidQrCode" src="../../assets/imgs/pc/andriodCode.png" alt="">
                                  </div>
                                  <img @mouseover="hoverAndroid(true)" @mouseleave="hoverAndroid(false)" class="swiper1-android ani"
                                       swiper-animate-effect="fadeInUp" :swiper-animate-duration="animateDuration"
                                       src="../../assets/imgs/pc/android.png" alt="">
                                </div>
                              </div>
                            </div>
<!--                            <div class="swiper1-3">-->
<!--                              <div class="weChat weChatHeader">-->
<!--                              <div class="weChatBox ani" swiper-animate-effect="fadeInUp"-->
<!--                                   :swiper-animate-duration="animateDuration">-->
<!--                                  <div class="weChatBg">-->
<!--&lt;!&ndash;                                    <p class="weChatQrCode"></p>&ndash;&gt;-->
<!--                                    <img class="weChatQrCode" src= "../../assets/imgs/pc/weChatShare.jpg" alt="">-->
<!--                                    <p class="weChatText1 weChatText">扫码关注趣开黑</p>-->
<!--                                    <p class="weChatText2 weChatText">微信公众号</p>-->
<!--                                  </div>-->
<!--                              </div>-->
<!--                              </div>-->
<!--                            </div>-->
<!--          <div class="swiper1-4">-->
<!--            <p class="qd">敬请期待</p>-->
<!--          </div>-->
        </div>
        <div class="swiper-slide">
          <!--          首页推荐-->
          <div class="swiperBgImg bg1">
          </div>
          <!--                  <img class="swiperBgImg" src="../../assets/imgs/homepage.png" alt="">-->
          <div class="swiper2-1 theSwiper ani" swiper-animate-effect="fadeInUp"
               :swiper-animate-duration="animateDuration">
            <img class="swiper2-text" src="../../assets/imgs/pc/recommend.png" alt="">
          </div>
          <div class="swiper2-2 theSwiper  ani" swiper-animate-effect="fadeInUp"
               :swiper-animate-duration="animateDuration">
            <img class="swiper2-mobile" src="../../assets/imgs/pc/mobile1.png" alt="">
          </div>
          <div class="swiper2-3 theSwiper  ani" swiper-animate-effect="pulse" :swiper-animate-duration="animateDuration"
               swiper-animate-delay="0.5s">
            <img class="swiper2-book" src="../../assets/imgs/pc/book.png" alt="">
          </div>
          <div class="swiper2-4 theSwiper">
            <img class="swiper2-line" src="../../assets/imgs/pc/mobileLine1.png" alt="">
          </div>
        </div>
        <div class="swiper-slide">
          <!--          开黑车队-->
          <div class="swiperBgImg bg2">
          </div>
          <!--                  <img class="swiperBgImg" src="../../assets/imgs/fleet.png" alt="">-->
          <div class="swiper3-1 theSwiper ani" swiper-animate-effect="fadeInUp"
               :swiper-animate-duration="animateDuration">
            <img class="swiper3-fleet " src="../../assets/imgs/pc/moblie2.png" alt="">
          </div>
          <div class="swiper3-2 theSwiper ani" swiper-animate-effect="pulse" :swiper-animate-duration="animateDuration"
               swiper-animate-delay="0.5s">
            <img class="swiper3-game" src="../../assets/imgs/pc/game.png" alt="">
          </div>
          <div class="swiper3-3 theSwiper ani" swiper-animate-effect="fadeInUp"
               :swiper-animate-duration="animateDuration">
            <img class="swiper3-text" src="../../assets/imgs/pc/fleetText.png" alt="">
          </div>
          <div class="swiper3-4 theSwiper">
            <img class="swiper3-line" src="../../assets/imgs/pc/mobileLine2.png" alt="">
          </div>
        </div>
        <div class="swiper-slide">
          <!--          派单大厅-->
          <div class="swiperBgImg bg3">
          </div>
          <!--                  <img class="swiperBgImg" src="../../assets/imgs/order.png" alt="">-->
          <div class="swiper4-1 theSwiper ani" swiper-animate-effect="fadeInUp"
               :swiper-animate-duration="animateDuration">
            <img class="swiper4-text" src="../../assets/imgs/pc/orderText.png" alt="">
          </div>
          <div class="swiper4-2 theSwiper ani" swiper-animate-effect="fadeInUp"
               :swiper-animate-duration="animateDuration">
            <img class="swiper4-mibile" src="../../assets/imgs/pc/mobile3.png" alt="">
          </div>
          <div class="swiper4-3 theSwiper ani" swiper-animate-effect="pulse" :swiper-animate-duration="animateDuration"
               swiper-animate-delay="0.5s">
            <img class="swiper4-earPhone" src="../../assets/imgs/pc/earPhone.png" alt="">
          </div>
          <div class="swiper4-4 theSwiper">
            <img class="swiper4-line" src="../../assets/imgs/pc/mobileLine3.png" alt="">
          </div>
        </div>
        <div class="swiper-slide">
          <!--          大神陪练-->
          <div class="swiperBgImg bg4">
          </div>
          <!--                  <img class="swiperBgImg" src="../../assets/imgs/playWith.png" alt="">-->
          <div class="swiper5-1 theSwiper ani" swiper-animate-effect="fadeInUp"
               :swiper-animate-duration="animateDuration">
            <!--左上-->
            <img class="swiper5-order" src="../../assets/imgs/pc/profilePhoto1.png" alt="">
          </div>
          <div class="swiper5-2 theSwiper ani" swiper-animate-effect="fadeInUp"
               :swiper-animate-duration="animateDuration">
            <!--左中-->
            <img class="swiper5-order" src="../../assets/imgs/pc/profilePhoto2.png" alt="">
          </div>
          <div class="swiper5-3 theSwiper ani" swiper-animate-effect="fadeInUp"
               :swiper-animate-duration="animateDuration">
            <!--左下-->
            <img class="swiper5-order" src="../../assets/imgs/pc/profilePhoto3.png" alt="">
          </div>
          <div class="swiper5-4 theSwiper ani" swiper-animate-effect="fadeInUp"
               :swiper-animate-duration="animateDuration">
            <!--右上-->
            <img class="swiper5-order" src="../../assets/imgs/pc/profilePhoto4.png" alt="">
          </div>
          <div class="swiper5-5 theSwiper ani" swiper-animate-effect="fadeInUp"
               :swiper-animate-duration="animateDuration">
            <!--右中-->
            <img class="swiper5-order" src="../../assets/imgs/pc/profilePhoto5.png" alt="">
          </div>
          <div class="swiper5-6 theSwiper ani" swiper-animate-effect="fadeInUp"
               :swiper-animate-duration="animateDuration">
            <!--右下-->
            <img class="swiper5-order" src="../../assets/imgs/pc/profilePhoto6.png" alt="">
          </div>
          <div class="swiper5-7 theSwiper ani" swiper-animate-effect="pulse" :swiper-animate-duration="animateDuration"
               swiper-animate-delay="0.5s">
            <!--右下-->
            <img class="swiper5-order" src="../../assets/imgs/pc/love.png" alt="">
          </div>
          <div class="swiper5-8 theSwiper ani" swiper-animate-effect="fadeInUp"
               :swiper-animate-duration="animateDuration">
            <!--右下-->
            <img class="swiper5-order" src="../../assets/imgs/pc/playWithText.png" alt="">
          </div>
        </div>

        <div class="swiper-slide">
          <!--          <img src="../../assets/imgs/background1.png" alt="">-->
          <div class="swiper6-1 theSwiper ani" swiper-animate-effect="zoomIn"
               :swiper-animate-duration="animateDuration">
            <img class="swiper1-title" src="../../assets/imgs/pc/logo2.png" alt="">
          </div>
                    <div class="swiper6-btn">
                      <div class="swiper1-2 theSwiper">
                        <div class="iosBox">
                          <div class="iosCodeBg" v-bind:style="' visibility: '+iosVisibility+';'">
                            <img class="iosQrCode" src="../../assets/imgs/pc/iosCode.png" alt="">
                          </div>
                          <img @mouseover="hoverIos(true)" @mouseleave="hoverIos(false)" class="swiper1-ios ani"
                               swiper-animate-effect="fadeInUp" :swiper-animate-duration="animateDuration"
                               src="../../assets/imgs/pc/iosStore.png" alt="">
                        </div>
                        <div class="androidBox">
                          <div class="androidCodeBg" v-bind:style="' visibility: '+androidVisibility+';'">
                            <img class="androidQrCode" src="../../assets/imgs/pc/andriodCode.png" alt="">
                          </div>
                          <img @mouseover="hoverAndroid(true)" @mouseleave="hoverAndroid(false)" class="swiper1-android ani"
                               swiper-animate-effect="fadeInUp" :swiper-animate-duration="animateDuration"
                               src="../../assets/imgs/pc/androidStore.png" alt="">
                        </div>
                      </div>
                    </div>
<!--                    <div class="swiper6-3">-->
<!--                      <div class="weChat weChatFooter">-->
<!--                        <div class="weChatBox borderBlack ani" swiper-animate-effect="fadeInUp"-->
<!--                             :swiper-animate-duration="animateDuration">-->
<!--                          <div class="weChatBg">-->
<!--                            <img class="weChatQrCode" src= "../../assets/imgs/pc/weChatShare.jpg" alt="">-->
<!--                            <p class="weChatText1 weChatText">扫码关注趣开黑</p>-->
<!--                            <p class="weChatText2 weChatText">微信公众号</p>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--          <div class="swiper6-5">-->
<!--            <p class="qd">敬请期待</p>-->
<!--          </div>-->
          <div class="swiper6-4">
            <PcFooter/>
          </div>
        </div>
      </Swiper>
      <div class="pcView-swiper-pagination" slot="pagination"></div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/header/header";
import PcFooter from "@/components/footer/footer"
import Request from "../../request/index"
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import 'swiper/css/swiper.css'
import * as swiperAni from '../../assets/animate/index.js'

export default {
  name: "HomePage",
  components: {
    Header,
    Swiper,
    SwiperSlide,
    PcFooter
  },
  data() {
    return {
      iosVisibility: "hidden",
      androidVisibility: "hidden",
      stateNumber: 0,
      showLogin: false,
      animateDuration: "0.8s",//动画播放速度
      swiperOptions: {
        loop: false,//循环
        autoplay: false,//自动播放
        direction: 'vertical',//竖向轮播
        spaceBetween: 10,
        // mousewheel: true,
        slidesPerView: 1,//同时显示个数
        speed: 600,//滚动速度
        mousewheel: {
          releaseOnEdges: true,//PC端释放滑动
        },
        pagination: {
          el: '.pcView-swiper-pagination',
          clickable: true
        },
        on: {
          init: function () {
            swiperAni.swiperAnimateCache(this); //隐藏动画元素
            swiperAni.swiperAnimate(this); //初始化完成开始动画
          },
          slideChangeTransitionStart: function () {
            swiperAni.swiperAnimate(this); //每个slide开始切换时也运行当前slide动画
            //this.slides.eq(this.activeIndex).find('.ani').removeClass('ani'); 动画只展现一次，去除ani类名
          }
        }
      }
    }
  },
  beforeMount() {
  },
  mounted() {

  },
  computed: {
    swiper() {
      return this.$refs.pageSwiper.Swiper;
    }
  },
  created() {
  },
  methods: {
    getList: function () {
    },
    login: function () {
      this.showLogin = true;
      this.$emit("state", this.showLogin);
    },
    changeLoginModal(val) {
      this.showLogin = val;
    },
    hoverIos(state) {
      if (state) {
        this.iosVisibility = "visible";
      } else {
        this.iosVisibility = "hidden";
      }
    },
    hoverAndroid(state) {
      if (state) {
        this.androidVisibility = "visible";
      } else {
        this.androidVisibility = "hidden";
      }
    }
  }
}
</script>
<style scoped>
@import url('../../assets/animate/animate.min.css');

.swiper1-1 {
  left: 36.56%;
  top: 20.53%;
  height: 21.11%;
  width: 26.77%;
}

.swiper1-title {
  width: 100%;
  display: block;
}

.theSwiper {
  position: absolute;
  /*display: flex;*/
  /*display:flex;*/
}

.swiper1-btn {
  height: 200px;
  width: 100%;
  position: absolute;
  top: 42%;
  text-align: center;
  margin: 0 auto;
}

.swiper6-btn {
  height: 200px;
  width: 100%;
  position: absolute;
  top: 40%;
  text-align: center;
  margin: 0 auto;
}

.swiper1-2 {
  position: relative;
  height: 200px;
  display: table;
  margin: 0 auto;

}

.swiper1-4 {
  position: absolute;
  height: 200px;
  display: table;
  margin: 0 auto;
  top: 68.51%;
  width: 100%;
  text-align: center;
}

.swiper6-5 {
  position: relative;
  height: 200px;
  display: table;
  margin: 0 auto;
  top: 63.51%
}

.qd {
  color: #AAAAAB;
  font-size: 50px;
  letter-spacing: 8px;
}

.swiper1-android {
  width: 170px;
  float: left;
  cursor: pointer;
  margin: 63px 0px 0px 50px;
}

.iosQrCode {
  transform: translate(-6%, 28%);
  width: 65%;
  height: 65%;
  background-size: 100%;
  display: inline-block;
}

.iosCodeBg {
  width: 180px;
  height: 180px;
  background: url("../../assets/imgs/pc/qrcodeLeft.png") center no-repeat;
  background-size: 100%;
  float: left;
  display: inline-block;
}

.iosBox {
  height: 200px;
  float: left;
  display: inline-block;
}

.swiper1-ios {
  width: 170px;
  float: left;
  cursor: pointer;
  margin-top: 63px;
}

.androidBox {
  height: 200px;
  display: inline-block;
  float: left;
}

.androidCodeBg {
  width: 180px;
  height: 180px;
  background: url("../../assets/imgs/pc/qrcodeRight.png") center no-repeat;
  background-size: 100%;
  display: inline-block;
}

.androidQrCode {
  transform: translate(6%, 28%);
  width: 65%;
  height: 65%;
  background-size: 100%;
  display: inline-block;
}

.weChat {
  width: 100%;
  height: 260px;
  text-align: center;
  position: absolute;
  margin: 0 auto;

}

.weChatHeader {
  top: 61.75%;
}

.weChatFooter {
  top: 49.4%;
}

.weChatQrCode {
  /*background: url("../../assets/imgs/pc/weChatShare.jpg");*/
  width: 100%;
  height: 100%;
  background-size: 100%;
  display: inline-block;
  overflow: hidden;
  padding: 0px;
}

.weChatText {
  width: 100%;
  height: 20px;
  text-align: center;
  font-family: "Microsoft YaHei UI";
  font-size: 16px;
  font-weight: 700;
  color: black;
}

.weChatBox {
  height: 252px;
  display: table;
  background: #FFFFFF;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  margin: 0 auto;
}

.borderBlack {
  border: 1px solid black;
}

.weChatBg {
  width: 200px;
  height: 200px;
}

.weChatText1 {
  margin-top: -5px;
}

.weChatText2 {
  margin-top: -10px;
}

.swiper1-3 {
  /*display: block;*/
  /*width: 100%;*/
}

.swiper2-1 {
  left: 19%;
  top: 24%;
  height: 30%;
  /*width: 27.65%;*/
}

.swiper2-text, .swiper2-mobile, .swiper2-book, .swiper2-line, .swiper3-line, .swiper3-text, .swiper3-game, .swiper4-text, .swiper4-line, .swiper4-earPhone, .swiper4-mibile, .swiper5-order {
  height: 100%;
}

.swiper4-text {
  /*position: absolute;*/
}

.swiper2-2 {
  left: 57.03%;
  top: 7.9%;
  height: 80%;
  /*width: 24.42%;*/
  z-index: 12;
  max-width: 100%;
}

.swiper2-2 > img {
  max-width: 100%;
}

.swiper2-3 {
  left: 49.84%;
  top: 24.81%;
  height: 17%;
  width: 14.63%;
  z-index: 13;
}

.swiper2-4 {
  left: 49.27%;
  top: 34.72%;
  height: 30.55%;
  z-index: 11;
}

.swiper3-1 {
  left: 19.06%;
  top: 7.87%;
  height: 80.46%;
  z-index: 12;
}

.swiper3-fleet {
  height: 100%;
}

.swiper3-2 {
  left: 37.86%;
  top: 7.87%;
  height: 17.31%;
  z-index: 13;
}

.swiper3-3 {
  left: 54.63%;
  top: 25.55%;
  height: 35.74%;
}

.swiper3-4 {
  left: 11.14%;
  top: 32.31%;
  height: 28.88%;
  z-index: 11;
}

.swiper4-1 {
  left: 18.9%;
  top: 24.81%;
  height: 35.55%;
}

.swiper4-2 {
  left: 57.86%;
  top: 9.81%;
  height: 79.07%;
  z-index: 12;
}

.swiper4-3 {
  left: 53.28%;
  top: 8.42%;
  height: 23.88%;
  z-index: 13;
}

.swiper4-4 {
  left: 50.57%;
  top: 34.35%;
  height: 30.55%;
  z-index: 11;
}

.swiper5-1 {
  left: 12.76%;
  top: 18.05%;
  height: 11.11%;
}

.swiper5-2 {
  left: 15.93%;
  top: 35.55%;
  height: 13.14%;
}

.swiper5-3 {
  left: 8.02%;
  top: 51.38%;
  height: 11.11%;
}

.swiper5-4 {
  left: 31.3%;
  top: 15.55%;
  height: 11.11%;
}

.swiper5-5 {
  left: 28.02%;
  top: 47.12%;
  height: 11.11%;
}

.swiper5-6 {
  left: 22.5%;
  top: 70.64%;
  height: 11.11%;
}

.swiper5-7 {
  left: 44.79%;
  top: 51.2%;
  height: 13.33%;
}

.swiper5-8 {
  left: 54.73%;
  top: 23.14%;
  height: 36.2%;
}

.swiper6-1 {
  left: 43.22%;
  top: 10.37%;
  height: 21.11%;
  width: 13.59%;
}

.swiper6-3 {
  width: 100%;
  height: 260px;
}

.swiper6-4 {
  width: 100%;
  position: absolute;
  bottom: 10px;
}

.bg1 {
  background: url("../../assets/imgs/pc/homepage.png");
  background-size: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  /*background-attachment: fixed; !*关键*!*/
  background-position: center;
}

.bg2 {
  background: url("../../assets/imgs/pc/fleet.png");
  background-size: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  /*background-attachment: fixed;  !*关键*!*/
  background-position: center;
}

.bg3 {
  background: url("../../assets/imgs/pc/order.png");
  background-size: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  /*background-attachment: fixed;  !*关键*!*/
  background-position: center;
}

.bg4 {
  background: url("../../assets/imgs/pc/playWith.png");
  background-size: 100%;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  /*background-attachment: fixed;  !*关键*!*/
  background-position: center;
}
</style>
<style scoped lang="less">
/*swpier样式*/
.swiper-slide > img {
  position: relative;
}

.swiperBgImg {
  width: 100%;
  height: 100%;
  position: absolute;
  //display: inline-block;
}

.swiper {
  position: relative;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  height: 100vh;
}

.pcView-swiper-pagination {
  right: 3% !important;
  position: fixed;
  z-index: 200;
  margin: 0 auto;
  width: 15px;
  height: 100px;
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}

.pcView-swiper-pagination /deep/ .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  opacity: 0.5;
  background-color: gray;
}

.pcView-swiper-pagination /deep/ .swiper-pagination-bullet-active {
  background-color: #659FFF;
  opacity: 0.9;
  width: 10px;
  height: 25px;
  border-radius: 10px;
}

.pc-container {
  .swiper-container {
    .swiper-pagination {
      position: fixed;
      width: 1000px;
    }
  }
}

.pcView-nextPage {
  position: fixed;
  z-index: 10;
  bottom: 3%;
  left: 50%;
  transform: translateX(-50%);
  width: 50px;
  height: 50px;
  background: url("../../assets/imgs/pc/next.png") center no-repeat;
  background-size: contain;
}

</style>
import Vue from 'vue'
import router from 'vue-router'
import HomePage from "@/views/Homepage/HomePage";
import Mobile from "@/views/Mobile/Mobile";

Vue.use(router);
const vueRouter = new router({
    mode: 'history',
    // base: process.env.BASE_URL,
    routes: [
        {
            path: '/',
            name: 'HomePage',
            component: HomePage
        }, {
            path: '/Mobile',
            name: 'Mobile',
            component: Mobile
        }, {
            path: '/details',
            name: 'Details',
            component: () => import( '@/views/Share/details/index'),
            meta:{
                title: '趣开黑'
            }
        }, {
            path: '/skills',
            name: '/Skills',
            component: () => import( '@/views/Share/skills/index'),
            meta:{
                title: '趣开黑'
            }
        }, {
            path: '/authentication',
            name: '/Authentication',
            component: () => import( '@/views/Authentication/authentication'),
            meta:{
                title: '大神认证'
            }
        }]
})

function isMobileDevice() { //判断当前设备是否为移动端
    const ua = navigator.userAgent.toLowerCase();
    const t1 = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(
        ua
    );
    const t2 = !ua.match("iphone") && navigator.maxTouchPoints > 1;
    return t1 || t2;
}

vueRouter.beforeEach((to, from, next) => {
    //进行判断，如果to路径没有匹配到现有的任何一个路由
    //作用：当to的路由为空时不跳转，同时当from的路由也为空时，则跳转到404页面
    if (to.path === "/") {
        if (isMobileDevice()) {
            next("/Mobile")
        }
    }
    if (to.path === "/Mobile") {
        if (!isMobileDevice()) {
            next("/")
        }
    }
    if (to.path === "/details") {
        if (to.query.userId === undefined) {
            next("/Mobile")
        }
    }
    if (to.path === "/skills") {
        if (to.query.id === undefined) {
            next("/Mobile")
        }
    }
    if (to.path === "/authentication") {
        document.title = to.meta.title
        next()
    }

    if (to.matched.length === 0) {
        //这里打印输出
        // console.log(to, from, next);
        //这里用三元表达式进行了判断，当from.name也就是跳转来的路由名称不为空，则直接跳转到from所代表的路由，否则跳转到404页面
        // from.name ? next({name: from.name}) : next("/404");
    } else {
        //如果to的路由名称不为空，则进行跳转
        next();
    }
})
export default vueRouter
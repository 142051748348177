<template>
  <div class="mobile-container">
    <div class="swiper-container mobile-swiper">
      <MobileHeader/>
      <Swiper :options="swiperOptions" ref="mobileSwiper">
        <!--        趣开黑-->

        <div class="swiper-slide">
          <div class="swiperBgImg bg1">
          </div>
          <div class="swiper1 theSwiper ani" swiper-animate-effect="zoomIn"
               :swiper-animate-duration="animateDuration">
            <img class="swiper-img" src="../../assets/imgs/mobile/container1.png" alt="">
          </div>
        </div>
        <div class="swiper-slide">
          <div class="swiperBgImg bg2">
          </div>
          <div class="swiper1 theSwiper ani" swiper-animate-effect="zoomIn"
               :swiper-animate-duration="animateDuration">
            <img class="swiper-img" src="../../assets/imgs/mobile/container2.png" alt="">
          </div>
        </div>
        <div class="swiper-slide">
          <div class="swiperBgImg bg3">
          </div>
          <div class="swiper1 theSwiper ani" swiper-animate-effect="zoomIn"
               :swiper-animate-duration="animateDuration">
            <img class="swiper-img" src="../../assets/imgs/mobile/container3.png" alt="">
          </div>
        </div>
        <div class="swiper-slide">
          <div class="swiperBgImg bg4">
          </div>
          <div class="swiper1 theSwiper ani" swiper-animate-effect="zoomIn"
               :swiper-animate-duration="animateDuration">
            <img class="swiper-img" src="../../assets/imgs/mobile/container4.png" alt="">
          </div>
        </div>
        <div class="swiper-slide">
          <div class="swiperBgImg bg5">
          </div>
          <div class="swiper1 theSwiper ani" swiper-animate-effect="zoomIn"
               :swiper-animate-duration="animateDuration">
            <img class="swiper-img" src="../../assets/imgs/mobile/container5.png" alt="">
          </div>
        </div>
        <div class="swiper-slide">
          <div class="swiperBgImg bg6">
          </div>
          <div class="swiper1 theSwiper">
            <img class="swiper-img ani" src="../../assets/imgs/mobile/container6.png" swiper-animate-effect="zoomIn"
                 :swiper-animate-duration="animateDuration" alt="">
            <div class="footer">
              <MobileFooter/>
            </div>
          </div>
        </div>
      </Swiper>
      <!--    <div class="download">-->
      <!--      <img @click="download" class="download-img" src="../../assets/imgs/mobile/download.png" alt="">-->
      <!--    </div>-->
      <div class="mobile-swiper-pagination" slot="pagination"></div>
    </div>
    <div class="guide" @click="clickGuide" v-show="wechat">
      <img src="../../assets/imgs/mobile/guide.png" alt="">
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import 'swiper/css/swiper.css'
import * as swiperAni from '../../assets/animate/index.js'
import MobileHeader from "@/components/header/mobileHeader";
import MobileFooter from "@/components/footer/mobileFooter";
import Vue from 'vue';
import {Toast} from 'vant';

Vue.use(Toast);
import "vant/lib/toast/index.less"

export default {
  name: "mobile",
  components: {
    MobileHeader,
    Swiper,
    SwiperSlide,
    MobileFooter
  },
  data() {
    return {
      animateDuration: "0.8s",//动画播放速度
      swiperOptions: {
        loop: false,//循环
        autoplay: false,//自动播放
        direction: 'vertical',//竖向轮播
        // spaceBetween: 10,
        slidesPerView: 1,//同时显示个数
        speed: 600,//滚动速度
        mousewheel: {
          releaseOnEdges: true,//PC端释放滑动
        },
        pagination: {
          el: '.mobile-rotation-pagination',
          clickable: true
        },
        on: {
          init: function () {
            swiperAni.swiperAnimateCache(this); //隐藏动画元素
            swiperAni.swiperAnimate(this); //初始化完成开始动画
          },
          slideChangeTransitionStart: function () {
            swiperAni.swiperAnimate(this); //每个slide开始切换时也运行当前slide动画
            //this.slides.eq(this.activeIndex).find('.ani').removeClass('ani'); 动画只展现一次，去除ani类名
          }
        }
      },
      wechat:false,
    }
  },
  computed: {
    swiper() {
      return this.$refs.mobileSwiper.Swiper;
    }
  },
  created() {
    document.title = '趣开黑官方APP';
    this.wechat = this.isWechat();
  },
  methods: {
    download: function () {
      // this.$message.info('暂未开放，敬请期待');
      // this.$toast('暂未开放，敬请期待');
    },
    isWechat() {
      let ua = window.navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        return true;
      } else {
        return false;
      }
    },
    clickGuide(){
      this.wechat = false;
    }
  }
}
</script>
<style scoped>
@media screen and (max-height: 740px) {
  .footer {
    position: absolute;
    width: 100%;
    bottom: 20%;
  }
}

@media screen and (max-height: 660px) {
  .footer {
    position: absolute;
    width: 100%;
    bottom: 15% !important;
  }
}

* {
  -webkit-text-size-adjust: none;
}
.guide{
  position:fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0,0,0,0.6);
  z-index: 30;
  top: 0;
  left: 0;
}
.guide img{
  width: 70vw;
  margin-left: 15vw;
}
.footer {
  position: absolute;
  width: 100%;
  bottom: 20%;
}

.download {
  position: fixed;
  z-index: 10;
  bottom: 5%;
  width: 100%;
  animation: orderMove 0.8s alternate infinite;
  text-align: center;
  margin: 0 auto;
}

.download-img {
  width: 64.28%;
  display: block;
  margin: 0 auto;
}

.bg1 {
  background: url("../../assets/imgs/mobile/background1.png");
  background-size: cover;
}

.bg2 {
  background: url("../../assets/imgs/mobile/background2.png");
  background-size: cover;
}

.bg3 {
  background: url("../../assets/imgs/mobile/background3.png");
  background-size: cover;
}

.bg4 {
  background: url("../../assets/imgs/mobile/background4.png");
  background-size: cover;
}

.bg5 {
  background: url("../../assets/imgs/mobile/background5.png");
  background-size: cover;
}

.bg6 {
  background: #FFFFFF;
}

.swiper1 {
  position: relative;
  text-align: center;
  font-size: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  display: block !important;
  padding-top: 6%;
}

.theSwiper {
  position: absolute;
  width: 100%;
  height: 100%;
}
</style>
<style scoped lang="less">
.swiper-slide > img {
  position: relative;
}

.swiperBgImg {
  width: 100%;
  height: 100%;
  position: absolute;
}

.swiper {
  position: relative;
}

.swiper-container {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  height: 100vh;
}

.mobile-swiper-pagination {
  right: 3% !important;
  position: fixed;
  z-index: 200;
  margin: 0 auto;
  width: 15px;
  height: 100px;
  top: 50%;
  transform: translate3d(0px, -50%, 0);
}

.mobile-swiper-pagination /deep/ .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  opacity: 0.5;
  background-color: gray;
}

.mobile-swiper-pagination /deep/ .swiper-pagination-bullet-active {
  background-color: #659FFF;
  opacity: 1;
}

</style>
<template>
<div class="mobile-footer">
  <p>公司名称：武汉卓趣科技有限公司</p>
  <p>地址：武汉市江汉区经济开发区江旺路8号5层（红提科技企业孵化器-H7071）</p>
  <p><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">互联网ICP备案：鄂ICP备2022009216号-1</a></p>
  <p><a href="https://www.12377.cn/" target="_blank">中央网信办（国家互联网信息办公室）违法和不良信息举报中心</a></p>
  <p><a href="https://www.12321.cn/" target="_blank">12321网络不良与垃圾信息举报受理中心</a></p>
  <p>
    <a href="https://ts.isc.org.cn/" target="_blank">互联网信息服务投诉平台</a>
    <span>&nbsp;|&nbsp;</span>
    <a href="http://jbts.mct.gov.cn/" target="_blank">12318全国文化市场举报平台</a>
  </p>
</div>
</template>

<script>
export default {
  name: "mobileFooter"
}
</script>

<style scoped>
.mobile-footer {
  margin:auto 0;
  font-size: 11px;
  font-family: Microsoft YaHei;
  color: #999999;
  font-weight: 500;
}
.mobile-footer a{
  color: #999999;
  text-decoration:none;
}
</style>